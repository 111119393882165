import React from 'react';
import { Link } from 'gatsby';
import logo from 'assets/icons/logo.svg';
import { Wrapper } from './styles';

const Navbar = () => {

  return (
    <Wrapper >
      <div className="top-l">
        <Link to="/"><img src={logo} alt="Obtura Audiovisual" width="35" height="35"/></Link>
      </div>
    </Wrapper>
    
  );
};

export default Navbar;
