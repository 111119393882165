import styled from 'styled-components';

export const Wrapper = styled.div`
	display: block;
	z-index: 5;
	top: 1.6rem;
	right: 1.8rem;
	cursor: pointer;
	transition: left 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	position: absolute;
`;
export const BarShort = styled.div`
	width: 1.2rem;
	height: .15rem;
	margin-bottom: .3rem;
	background-color: white;
	transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91),
	opacity 500ms,
	box-shadow 250ms,
	background-color 500ms;
	@media (max-width: 600px){
		width: 0.8rem;
	}

	${({ top, sidebar, theme }) =>
		top &&
		sidebar &&
		`
		background-color: #fff;
		transform: translateY(8px) rotate(-135deg);
		
	`}

	${({ mid, sidebar }) =>
		mid &&
		sidebar &&
		`
		transform: scale(0);
		`}

	${({ bottom, sidebar, theme }) =>
		bottom &&
		sidebar &&
		`
			background-color: #212121;
			transform: translateY(-6px) rotate(-45deg);
	`}
`
export const Bar = styled.div`
	width: 1.6rem;
	height: .15rem;
	margin-bottom: .3rem;
	background-color: white;
	transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91),
	opacity 500ms,
	box-shadow 250ms,
	background-color 500ms;
	@media (max-width: 600px){
		width: 1.3rem;
	}
${({top, sidebar, theme}) =>
	sidebar && `
		`
	}
${({ top, sidebar, theme }) =>
	
    top &&
    sidebar &&
		`
		background-color: ${theme.backgroundLightColor};
		transform: translateY(1px) rotate(-135deg);
		position: fixed;
	`}

	${({ mid, sidebar }) =>
    mid &&
    sidebar &&
		`
		transform: scale(0);
		`}

	${({ bottom, sidebar, theme }) =>
    bottom &&
    sidebar &&
		`
			background-color: ${theme.backgroundLightColor};
			transform: translateY(-6px) rotate(-45deg);
			position: fixed;
	`}
`;
