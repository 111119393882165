import React from 'react';
import NavbarLinks from '../navbarLinks';
import { Wrapper } from './styles';
import logo from 'assets/icons/logo.svg';
const Sidebar = ({ sidebar, toggle }) => {
  
  return (
  <Wrapper active={sidebar} onClick={toggle}>
    <NavbarLinks />
    <img src={logo} className="logo" alt="Obtura Audiovisual" width="600" height="600"/>
  </Wrapper>
  )
};

export default Sidebar;
