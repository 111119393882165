import React from 'react';
import Link from "gatsby-link";

export const accordionLinks = ({ services }) => {

    if (!services) {
        return;
    }

    const servicesSlider = [];

    services.forEach((service) => {
        let slug = null;
        let title = null;
        if (service.hasOwnProperty("node")) {
            slug = service.node.slug.current
            title = service.node.h1__title
        }

        servicesSlider.push(
            {
                slug,
                title,
            }
        )
    })

    const services_render = [];

    servicesSlider.forEach((service, index) => (
        services_render.push(
            <span key={service.slug}>
                {service.slug &&
                    <li className="nav-subitem"  ><Link to={`/servicios/${service.slug}/`} key={index}> {service.title}</Link></li>
                }
            </span>
        )
    ))

    return (
        <>
            {services_render}
        </>
    );
};

export default accordionLinks;