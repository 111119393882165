import {
    defaultDescription,
    defaultTitle,
    social,
    socialLinks,
    logo,
    url,
} from "data/config";
import React from "react";
import Helmet from "react-helmet";

export const SEO = ({
                        title = defaultTitle,
                        description = defaultDescription,
                        location = "",
                        robots = "index, follow",
                        canonical = "",
                        image = "",
                    }) => {
    let canonicalClean = "";
    if (canonical !== "") {
        canonicalClean = `${canonical}/`;
    }
    let thumbnail = logo;
    if(image !== ""){
       thumbnail = image;
    }
    return (
        <Helmet>
        <meta name="description" content={description} />
    <meta name="image" content={thumbnail} />

    <meta property="og:url" content={`${url}${canonicalClean}`} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={thumbnail} />
    <meta property="fb:app_id" content={social.facebook} />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:creator" content={socialLinks.twitter} />
    <meta name="twitter:site" content={social.twitter} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image:src" content={thumbnail} />
    <link rel="publisher" href={socialLinks.google} />
    <link rel="canonical" href={`${url}${canonicalClean}`} />
    <title>{title}</title>
    <html lang="es" dir="ltr" />
    <meta name="robots" content={robots} />
    </Helmet>
);
};
