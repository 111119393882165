import styled from 'styled-components';
import theme from 'styles/style';

export const Wrapper = styled.div`
  font-size: ${theme.fsFooter };
  padding: 1em 0em;
`;

export const Flex = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (max-width: ${theme.tablet}) {
    flex-direction: column-reverse;
    text-align: left;
    align-items: flex-start;
  }
`;

export const Legal = styled.div`
padding:0;
display:flex;
    a {
      color:  ${theme.textColor};
      text-decoration: none;
      padding-left: 1em;
      line-height: 1.3em;
      transition: 0.4s;
      &:first-child{
      padding-left: 0;
      }
    }
    a:hover {
      color: ${theme.orange};
      transition: 0.4s;
    }
    @media (max-width: ${theme.device}){
      //flex-direction: column;
      //margin-bottom: 1em;
      text-align: left;
    }
    
`;

export const Details = styled.p`
    padding:0;
    margin:0;
`;
