// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-como-trabajamos-js": () => import("./../../../src/pages/como-trabajamos.js" /* webpackChunkName: "component---src-pages-como-trabajamos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-aviso-legal-mdx": () => import("./../../../src/pages/legal/aviso-legal.mdx" /* webpackChunkName: "component---src-pages-legal-aviso-legal-mdx" */),
  "component---src-pages-legal-politica-de-cookies-mdx": () => import("./../../../src/pages/legal/politica-de-cookies.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-mdx" */),
  "component---src-pages-legal-politica-de-privacidad-mdx": () => import("./../../../src/pages/legal/politica-de-privacidad.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-mdx" */),
  "component---src-pages-nuestro-trabajo-index-js": () => import("./../../../src/pages/nuestro-trabajo/index.js" /* webpackChunkName: "component---src-pages-nuestro-trabajo-index-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-servicios-index-js": () => import("./../../../src/pages/servicios/index.js" /* webpackChunkName: "component---src-pages-servicios-index-js" */),
  "component---src-templates-isolated-service-js": () => import("./../../../src/templates/isolated-service.js" /* webpackChunkName: "component---src-templates-isolated-service-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

