import styled from 'styled-components';
import theme from 'styles/style.js';
import orangeCircle from '../../../../assets/icons/circle-orange.svg';
import blueCircle from '../../../../assets/icons/circle-blue.svg';
import greenCircle from '../../../../assets/icons/circle-green.svg';
import greenCircleSublist from '../../../../assets/icons/circle-green-sublist.svg';

export const Wrapper = styled.div`
	padding: 3rem 0 0 1.5rem;
	display: flex;
	flex-direction: column;
	line-height: 3rem;
	
	li {
		list-style: none;
		font-family: ${theme.black};
		font-size: 1.2em;
		letter-spacing: 1px;

		a {
			line-height: 1.5em;
			color: ${theme.backgroundLightColor};
			font-weight: bold;
			text-transform: uppercase;

			&:hover {
				color: ${theme.primaryColor};
				transition: 0.3s;
			}
		}

		&.first, &.fourth {
			list-style-image: url('${orangeCircle}');
		}

		&.second, &.fifth  {
			list-style-image: url('${blueCircle}');
		}

		&.third, &.sixth {
			list-style-image: url('${greenCircle}');
		}

		.icon {
			color: ${theme.backgroundLightColor};
			display: inline-block;
			transition: 0.3s;
			margin-left: .2em;
            padding: .2em .6em;
			&:hover {
				color: ${theme.primaryColor};
				cursor: pointer;
			}

			&--active {
				transform: rotate(45deg);
				
			}
		}
        
		ul.sublist {
			padding-bottom: 1em;
			max-height: 100rem;
			transition: all .35s cubic-bezier(0.6, 0.05, 0.28, 0.91);
			padding: 0 0 1em 1.2em;
		}
          .subitem__point {
            color: ${theme.green};
            //font-size: 60px;
            //vertical-align:middle;
            //line-height:20px;
            font-family: ${theme.black};
          }
          .sublist__hidden {
            //visibility: hidden;
            //position: absolute;
            transform: scale(0) translateY(-30em) translateX(-45em);
            //line-height: 0em;
            height: 0;
            transition: 0.5s;
			font-size: 0.7em!important;
			line-height: 2.2em;
            transition: 0.5s;
			font-family: ${theme.normal};
          }

		ul.sublist li {
			font-size: 0.7em!important;
			line-height: 1.8em;
            transition: 0.5s;
			font-family: ${theme.normal};
			padding-bottom: 0.6em;
			list-style-image: url('${greenCircleSublist}');
		}

		ul.sublist li a {
			margin: 0!important;
		}
	}
  }
`;