import styled from 'styled-components';
import theme from 'styles/style.js';


export const Wrapper = styled.div`
  position: fixed;
  z-index: 4;
  top: 0px;
  right: -275px;
  width: 0;
  opacity: 0;
  height: 100%;
  background-color: ${theme.backgroundDarkColor};
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  overflow: hidden;

  .logo {
    position: absolute;
    pointer-events: none;
    left: 35%;
    bottom: -15%;
    opacity: 0.3;
    z-index: -1;
  }

  ${({ active }) =>
    active &&
    `
			width: 30%;
			right: 0px;
			opacity: 1;

      @media (min-width: 1200px) and (max-width: 1400px) {
				width: 40%;
			}

      @media (min-width: 961px) and (max-width: 1200px) {
				width: 50%;
			}

			@media (max-width: 960px) {
				width: 75%;
			}

			@media (max-width: 600px) {
				width: 100%;
			}
	`}
`;
