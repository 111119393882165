import React from 'react';
import {Wrapper, Bar, BarShort} from './styles';

const Hamburger = ({ sidebar, toggle }) => {
  
  return (
    <Wrapper sidebar={sidebar} onClick={toggle}>
      <div className="right-s">
      <Bar top sidebar={sidebar} />
      <BarShort mid sidebar={sidebar} />
      <Bar bottom sidebar={sidebar} />
      </div>
    </Wrapper>
  )
};

export default Hamburger;
