import React, {createRef, useEffect, useLayoutEffect, useState} from 'react';
import 'styles/index.scss';
import BannerCookies from '../GTM/Banner';
import Header from '../header';
import {Footer} from "../footer";
import theme from "styles/style";
import ButtonToTop from "../../ui/atoms/ButtonToTop";


const Layout = ({
                    children,
                    start=null,
                    block_1=null,
                    block_2 = null ,
                    block_3 = null ,
                    block_4 = null ,
                    block_5 = null ,
                    end = null,
                    adjust = {
                        start: {left: 0, color: `${theme.orange}`, size: 400, center: false, animation: false, top: 0},
                        block_1: {left: 150, color: `${theme.blue}`, size: 300, center: false, animation: false, top: 0},
                        block_2: {left: 0, color: `${theme.blue}`, size: 300, center: false, animation: false, top: 0},
                        block_3: {left: 0, color: `${theme.blue}`, size: 300, center: false, animation: false, top: 0},
                        block_4: {left: 0, color: `${theme.blue}`, size: 200, center: false, animation: false, top: 0},
                        block_5: {left: 0, color: `${theme.green}`, size: 150, center: true, animation: false, top: 0},
                    }
}) => {
    let containerLeft = 0;
    let containerRight = 0;
    let positionStart = 0;
    let positionEnd = 0;
    let positionStartLeft = 0;
    let positionCircleTop = 0;
    let positionCircleLeft = 0;


    let positionBlock1 = 0;
    let positionBlock1Left = 0;
    let positionBlock2 = 0;
    let positionBlock3 = 0;
    let positionBlock4 = 0;
    let positionBlock5 = 0;

    const [blue, setBlue] = useState(false);
    const [center, setCenter] = useState(false);
    const [animation, setAnimation] = useState(false);
    const [green, setGreen] = useState(false);
    const [endClass, setEnd] = useState(false);
    const  [viewButtonToTop, setViewButtonToTop] = useState(1080);
    let circle ={left: 0, color: `${theme.orange}`, size: 400, center: false};
    const scrollY = createRef(0);




    function managementClass(){
        scrollY.current = window.scrollY ;
        let bloqueSelected;
        const y = scrollY.current;
        setEnd(false);
        setBlue(false);
        setGreen(false);
        switch (true) {
            case (y <= positionStart): // start
                positionCircleTop = positionStart;
                positionCircleLeft = containerLeft + adjust.start.left;
                circle = {...adjust.start};
                break;
            case (y >= positionStart && y <= positionBlock1 ): // Bloque 1
                positionCircleTop = positionBlock1;
                positionCircleLeft = containerLeft + adjust.block_1.left ;
                circle = {...adjust.block_1};
                break;
            case ( block_2 && y >= positionBlock1 && y <= positionBlock2 ): // Bloque 2
                positionCircleTop = positionBlock2;
                positionCircleLeft = containerLeft + adjust.block_2.left;
                circle = {...adjust.block_2};
                break;
            case (block_3 && y >= positionBlock2 && y <= positionBlock3  ): // Bloque 3
                positionCircleTop = positionBlock3;
                positionCircleLeft =  containerLeft + adjust.block_3.left ;
                circle = {...adjust.block_3};
                break;
            case (block_4 && y >= positionBlock3 && y <= positionBlock4  ): // Bloque 4
                positionCircleTop = positionBlock4;
                positionCircleLeft =  containerLeft + adjust.block_4.left ;
                circle = {...adjust.block_4};
                break;
            case (block_5 &&  y >= positionBlock4 && y <= positionBlock5  ): // Bloque 5
                positionCircleTop = positionBlock5;
                positionCircleLeft =  containerLeft + adjust.block_5.left ;
                circle = {...adjust.block_5};
                break;
            default:
                circle = {left: 100, color: `${theme.green}`, size: 150};
                setEnd(true);
                break;

        }
        setProperties();
    }
     const setProperties = () => {
        // document.body.style.setProperty('--start-top', positionStart);
        // document.body.style.setProperty('--start-left', positionStartLeft);
        setCenter(circle.center);
        setAnimation(circle.animation);
        document.body.style.setProperty('--block-top', positionCircleTop);
        document.body.style.setProperty('--block-end', positionEnd);
        document.body.style.setProperty('--block-left', circle.left);
        document.body.style.setProperty('--container-left', containerLeft);
        document.body.style.setProperty('--container-right', containerRight);
        document.body.style.setProperty('--circle-color', circle.color);
        document.body.style.setProperty('--circle-size', circle.size);
    }
    const setSizes = () => {
        document.body.style.setProperty('--100vh',  window.innerHeight );
        if(start !== null){
            let { offsetTop, offsetLeft, offsetWidth } = start.current;
            setViewButtonToTop(offsetTop);
            positionStart = offsetTop;
            containerLeft = offsetLeft;
            containerRight = offsetWidth + offsetLeft;
            positionStartLeft = offsetLeft + adjust.start;
            positionStartLeft = offsetLeft + adjust.start;
        }
        if(end){
            let { offsetTop } = end.current;
            positionEnd = offsetTop;
        }
        if(block_1){
            let { offsetTop: block1Top } = block_1.current;
            positionBlock1 = block1Top;
            positionBlock1Left = containerLeft + adjust.block_1;
        }

        if(block_2) {
            let { offsetTop } = block_2.current;
            positionBlock2 = offsetTop;
        }
        if(block_3) {
            let { offsetTop } = block_3.current;
            positionBlock3 = offsetTop;
        }
        if(block_4) {
            let { offsetTop } = block_4.current;
            positionBlock4 = offsetTop;
        }
        if(block_5) {
            let { offsetTop } = block_5.current;
            positionBlock5 = offsetTop;
        }
        setProperties();
    }
    function managementButtonToTop(){
        if( viewButtonToTop < window.scrollY ) {
            document.body.style.setProperty('--to-top', 'visible');
        } else {
            document.body.style.setProperty('--to-top', 'hidden');
        }

    }
    useLayoutEffect(()=>{
        managementButtonToTop();
        document.body.style.setProperty('--100vh',  window.innerHeight );
        if(start){
            setSizes();
            window.addEventListener('resize', setSizes );
            document.addEventListener('scroll', managementClass);
            document.addEventListener('scroll', managementButtonToTop );
            return () => {
                window.removeEventListener('resize', setSizes);
                document.removeEventListener('scroll', managementClass);
                document.removeEventListener('scroll', managementButtonToTop);
                window.removeEventListener('load', managementButtonToTop );
            };
        }
    }, []);

    useEffect(()=>{
        setTimeout(()=> {
            setSizes()
        }, 500)
    }, []);

    return (
        <div className={"circle__content"}>
             {/*lo encapsulo en una container para pillar el ancho de la columna centrar */}
             {/*   <div className={"circle__background "}></div>*/}
             <div className="circle__content--container">
                <div className={`circle__background  
                    ${center? "circle__background--center" : "" } 
                    ${animation? "circle__background--animation" : "" } 
                    ${endClass? "circle__background--end" : "" }`}>
                </div>
             </div>
         {/* <div className={"circle__content"}> */}
            <Header />
             {children}
            <Footer />
            <ButtonToTop />

         {/* </div> */}
         <BannerCookies />
         </div>
    );
}

export default Layout;