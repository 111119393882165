import React, { useState } from 'react';
import { Wrapper } from './styles';
import Link from "gatsby-link";
import AccordionLinks from "./accordionLinks";
import {graphql, useStaticQuery} from 'gatsby'

const NavbarLinks = ({data}) => {

  const [isActive, setIsActive] = useState(false);

    const sanityData = useStaticQuery(graphql`
        query navMenu{
            allSanityService(filter: {has_root: {eq: false}}) {
                edges {
                    node {
                        slug {
                            current
                        }
                        h1__title
                    }
                }
            }
            rootMenu: allSanityService(filter: {has_root: {eq: true}}) {
                edges {
                    node {
                        slug {
                            current
                        }
                       h1__title 
                    }
                }
            }
        }
    `)

    function toggleServices(e){
        setIsActive(!isActive)
        e.stopPropagation();
        e.preventDefault();
    }

    const isolatedServices = [];
    sanityData.rootMenu.edges.forEach((edge, index) =>{
        isolatedServices.push( <li className="fourth nav-item" key={index} > <Link to={`/servicios/${edge.node.slug.current}/`}> {edge.node.h1__title} </Link> </li>)
    })
  
  return (
    <Wrapper>
        <ul className= {"nav-list"}>
          <li className="first nav-item"><Link to={"/"}>Inicio</Link></li>
          <li className="second nav-item"><Link to={"/quienes-somos/"}>Quiénes somos</Link></li>
          <li className="third nav-item">
            <Link to={"/servicios/"}>Servicios</Link><span onClick={(e) => toggleServices(e)} className={`icon ${isActive ? "icon--active" : "" } `}>{" "}+</span>
            <ul className={ isActive ? "sublist" : "sublist__hidden"}><AccordionLinks services={sanityData.allSanityService.edges}/></ul>
          </li>
            {isolatedServices}
          <li className="fifth nav-item"><Link to={"/como-trabajamos/"}>Cómo trabajamos</Link></li>
          <li className="sixth nav-item"><Link to={"/nuestro-trabajo/"}>Nuestros trabajos</Link></li>
          <li className="first nav-item"><Link to={"/contacto/"}>Contacto</Link></li>
        </ul>
    </Wrapper>
  )
};

export default NavbarLinks;
