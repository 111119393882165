import { useState } from 'react';

export default () => {

const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    if (sidebar) {
        setSidebar(false);
    } else {
        setSidebar(true);
    }
  };

  return [sidebar, toggleSidebar];
};
