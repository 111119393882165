import styled from 'styled-components';
import theme from 'styles/style.js';

export const Container = styled.div`
  max-width: ${theme.maxWith};
  margin: 0 auto;
  width: 90%;
  @media (min-width: 601px) {
    width: 90%;
  }
  @media (min-width: 993px) {
    width: 80%;
  }
`;
