import React from 'react';
import {Container}  from 'components/ui/objects/container';
import { Wrapper, Flex, Legal, Details } from './styles';
import {graphql, useStaticQuery} from "gatsby";

export const Footer = () => {

    const data = useStaticQuery(graphql`
        query HeaderQuery {
            site {
              siteMetadata {
                title
                foundingDate
              }
            }
          }
    `);
    return (
        <Wrapper>
            <Flex as={Container}>
                <Details>
                    © {data.site.siteMetadata.foundingDate} | {data.site.siteMetadata.title}
                </Details>
                <Legal>
                    <a href="/legal/aviso-legal/" target="_blank" rel="nofollow noopener noreferrer">Aviso legal</a>
                    <a href="/legal/politica-de-privacidad/" target="_blank" rel="nofollow noopener noreferrer">Política de
                        privacidad</a>
                    <a href="/legal/politica-de-cookies/" target="_blank" rel="nofollow noopener noreferrer">Cookies</a>
                </Legal>
            </Flex>
        </Wrapper>
    );
}
