import React from 'react';
import Navbar from './navbar';
import Hamburger from './hamburger';
import Sidebar from './sidebar';
import { Wrapper, Overlay } from './styles';
import useSideBar from '../../../hooks/useSideBar';


export const Header = () => {

  const [sidebar, toggle] = useSideBar();

  return (
    <Wrapper>
      <Overlay sidebar={sidebar} onClick={toggle} />
      <Navbar />
      <Hamburger sidebar={sidebar} toggle={toggle} />
      <Sidebar sidebar={sidebar} toggle={toggle} />
    </Wrapper>

  );
};

export default Header;